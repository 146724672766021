import categorysvg from "@/assets/svg/category.svg";
export default {
  path: "/category",
  redirect: "/category/index",
  meta: {
    icon: categorysvg,
    title: "纸样管理",
    rank: 1
  },
  children: [
    {
      path: "/category/index",
      name: "category",
      component: () => import("@/views/category/index.vue"),
      meta: {
        title: "纸样管理",
        keepAlive: false,
        roles: ["style"]
      }
    },
    {
      path: "/category/categoryManager",
      name: "categoryManager",
      component: () => import("@/views/category/categoryManager.vue"),
      meta: {
        title: "添加/修改纸样",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/category/categoryDetail",
      name: "categoryDetail",
      component: () => import("@/views/category/categoryDetail.vue"),
      meta: {
        title: "纸样",
        keepAlive: false,
        showLink: false
      }
    }
  ]
} as RouteConfigsTable;
