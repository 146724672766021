import { $t } from "@/plugins/i18n";
import orderManager from "@/assets/svg/order.svg";
const Layout = () => import("@/layout/index.vue");

export default {
  path: "/order",
  redirect: "/order/index",
  meta: {
    icon: orderManager,
    title: "订单管理",
    rank: 2
  },
  children: [
    {
      path: "/order/index",
      name: "Order",
      component: () => import("@/views/order/index.vue"),
      meta: {
        title: "订单管理",
        keepAlive: false,
        roles: ["order"]
      }
    },
    {
      path: "/order/createOrder",
      name: "OrderManager",
      component: () => import("@/views/order/createOrder.vue"),
      meta: {
        title: "下单",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/order/orderDetail",
      name: "OrderDetail",
      component: () => import("@/views/order/orderDetail.vue"),
      meta: {
        title: "订单详细",
        keepAlive: false,
        showLink: false
      }
    }
  ]
};
