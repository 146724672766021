import axios from "axios";
import { defineStore } from "pinia";
import { usePermissionStoreHook } from "./permission";
/** 权限 */
interface Permissions {
  /** 撤消他人登记 */
  work_revoke_all?: boolean;
  /** 撤消已登记 */
  work_revoke?: boolean;
  /** 登记工序 */
  work_invoke?: boolean;
  /** 工序质检 */
  work_step_check?: boolean;
  /** 协助扫菲 */
  work_assist?: boolean;
  /** 协助全部员工 */
  work_assist_all?: boolean;
  /** 单扎多工序登记 */
  work_multi_step?: boolean;
  /** 单工序多扎登记 */
  work_multi_piece?: boolean;
  /** 多人登记 */
  work_multi_checkIn?: boolean;
  /** 选择登记 */
  work_search?: boolean;
  /** 添加纸样 */
  style_add?: boolean;
  /** 修改纸样 */
  style_mod?: boolean;
  /** 查看纸样 */
  style_view?: boolean;
  /** 删除纸样 */
  style_del?: boolean;
  /** 工序同步 */
  style_step_sync?: boolean;
  /** 编辑工序 */
  style_step_mod?: boolean;
  /** 预算成本 */
  style_cost?: boolean;
  /** 查看工序 */
  style_step_view?: boolean;
  /** 编辑工序单价 */
  style_step_price?: boolean;
  /** 查看工序单价 */
  style_step_priceView?: boolean;
  /** 添加订单 */
  order_add?: boolean;
  /** 查看订单 */
  order_view?: boolean;
  /** 删除订单 */
  order_del?: boolean;
  /** 裁床编菲 */
  order_ticket?: boolean;
  /** 跟单员 */
  order_staff?: boolean;
  /** 编辑工序 */
  order_step_mod?: boolean;
  /** 预算成本 */
  order_cost?: boolean;
  /** 查看工序 */
  order_step_view?: boolean;
  /** 编辑工序单价 */
  order_step_price?: boolean;
  /** 查看工序单价 */
  order_step_priceView?: boolean;
  /** 修改订单资料 */
  order_update?: boolean;
  /** 增加菲票 */
  order_ticket_add?: boolean;
  /** 修改菲票 */
  order_ticket_mod?: boolean;
  /** 删除菲票 */
  order_ticket_del?: boolean;
  /** 撤销整单登记 */
  order_clear?: boolean;
  /** 查看薪资 */
  salary_view?: boolean;
  /** 修改薪资 */
  salary_mod?: boolean;
  /** 工资结算 */
  salary_settle?: boolean;
  /** 取消结算 */
  salary_cancel?: boolean;
  /** 导出记录 */
  salary_export?: boolean;
  /** 入职审核 */
  employee_add?: boolean;
  /** 查看员工 */
  employee_view?: boolean;
  /** 离职员工 */
  employee_del?: boolean;
  /** 修改员工 */
  employee_mod?: boolean;
  /** 员工权限 */
  employee_auth?: boolean;
  /** 新增部门 */
  employee_dpm_add?: boolean;
  /** 修改部门 */
  employee_dpm_mod?: boolean;
  /** 删除部门 */
  employee_dpm_del?: boolean;
  /** 新增职位 */
  employee_role_add?: boolean;
  /** 修改职位 */
  employee_role_mod?: boolean;
  /** 删除职位 */
  employee_role_del?: boolean;
  /** 编辑客户 */
  customer_manager?: boolean;
  /** 删除客户 */
  customer_del?: boolean;
  /** 打印机管理 */
  others_printer?: boolean;
  /** 系统操作日志 */
  others_logs?: boolean;
}
export const useUserInfo = defineStore("userInfo", {
  state: () => {
    return { code: null, data: null, message: null };
  },
  getters: {
    permissionsArr: (state): Array<string> => {
      let result: Array<string> = state.data?.permissions || [];
      if (state.data?.staff?.is_admin) {
        result.push("admin");
      }
      return result;
    },
    /** 用户当前权限 */
    permissions(): Permissions {
      return Object.fromEntries(this.permissionsArr.map(v => [v, true]));
    },
    isAdmin(): boolean {
      return this.data?.staff && this.data?.staff.is_admin;
    }
  },
  actions: {
    async setUserInfo() {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const res = await axios.post("/main/user/fullInfo");
          Object.assign(this, res.data);
          resolve(res.data.data);
          usePermissionStoreHook().updateRoles(this.permissionsArr);
        } catch (e) {
          reject(e);
        }
      });
    },
    getUserInfo() {
      try {
        if (this.data && this.data.factory && !this.data.factory.address_full) {
          this.data.factory.address_full =
            this.data.factory.address["province"] +
            this.data.factory.address["city"] +
            this.data.factory.address["area"] +
            this.data.factory.address["street"];
        }
      } catch (error) {}

      return this.data;
    },
    /**
     * 更新用户的权限
     * @param permissions 权限列表
     */
    updatePermissions(permissions) {
      this.data.permissions = permissions;
      usePermissionStoreHook().updateRoles(this.permissionsArr);
    }
  }
});
