import logsvg from "@/assets/svg/log.svg";

export default {
  path: "/logManager",
  redirect: "/logManager/index",
  meta: {
    icon: logsvg,
    title: "日志管理",
    rank: 7
  },
  children: [
    {
      path: "/logManager/index",
      name: "logManager",
      component: () => import("@/views/logManager/index.vue"),
      meta: {
        title: "日志管理",
        keepAlive: false,
        roles: ["others_logs"]
      }
    }
  ]
} as RouteConfigsTable;
