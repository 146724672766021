import settingsvg from "../../assets/svg/setting.svg";
export default {
  path: "/setting",
  redirect: "/setting/index",
  meta: {
    icon: settingsvg,
    title: "设置管理",
    rank: 6
  },
  children: [
    {
      path: "/setting/index",
      name: "setting",
      component: () => import("@/views/setting/index.vue"),
      meta: {
        title: "设置管理",
        keepAlive: false,
        roles: ["others_setting"]
      }
    }
  ]
} as RouteConfigsTable;
