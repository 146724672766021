<template>
  <el-config-provider :locale="currentLocale">
    <router-view />
    <ReDialog />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Action, ElConfigProvider, ElMessageBox } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import en from "element-plus/lib/locale/lang/en";
import { ReDialog } from "@/components/ReDialog";
import { useUserInfo } from "./store/modules/useInfo";
import { Socket } from "socket.io-client";
import router from "./router";
import { useUserStoreHook } from "@/store/modules/user";

export default defineComponent({
  name: "app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
    ReDialog
  },
  async mounted() {
    const routeName = router.currentRoute.value.name;
    const user = useUserInfo();
    const socket = inject("socket") as Socket;

    socket.on("permissionUpdate", ({ factory_id, permission }) => {
      const userInfo = user.getUserInfo();
      if (userInfo && userInfo.factory && userInfo.factory.id === factory_id) {
        user.updatePermissions(permission);
      }
    });

    socket.on("offline", (data) => {
      socket.disconnect();
      window.localStorage.removeItem("token");
      ElMessageBox.alert('账号已在其它设备登录,如非本人操作请及时修改密码', '提示', {
        showClose: false,
        confirmButtonText: '确定',
        callback: (action: Action) => {
          useUserStoreHook().logOut();
        },
      });
    });

    //  检测是否登录
    if (routeName !== "Welcome") {
      try {
        await user.setUserInfo();
        if (routeName === "Login") {
          router.push('/');
        }
      } catch (e) {
      }
    }
  },
  computed: {
    currentLocale() {
      return this.$storage.locale?.locale === "zh" ? zhCn : en;
    }
  }
});
</script>
