buttons:
  hsLoginOut: 退出系统
  hsfullscreen: 全屏
  hsexitfullscreen: 退出全屏
  hsrefreshRoute: 刷新路由
  hslogin: 登录
  hsadd: 新增
  hsmark: 标记/取消
  hssave: 保存
  hssearch: 搜索
  hsexpendAll: 全部展开
  hscollapseAll: 全部折叠
  hssystemSet: 打开项目配置
  hsdelete: 删除
  hsreload: 重新加载
  hscloseCurrentTab: 关闭当前标签页
  hscloseLeftTabs: 关闭左侧标签页
  hscloseRightTabs: 关闭右侧标签页
  hscloseOtherTabs: 关闭其他标签页
  hscloseAllTabs: 关闭全部标签页
  hswholeFullScreen: 全屏
  hswholeExitFullScreen: 退出全屏
  hscontentFullScreen: 内容区全屏
  hscontentExitFullScreen: 内容区退出全屏
menus:
  hshome: 首页
  hsorder: 订单管理
  hslogin: 登录
  hsabnormal: 异常页面
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: 权限管理
  permissionPage: 页面权限
  permissionButton: 按钮权限

status:
  hsLoad: 加载中...
login:
  username: 账号
  password: 密码
  login: 登录
  usernameReg: 请输入账号
  passwordReg: 请输入密码
  passwordRuleReg: 密码格式应为8-18位数字、字母、符号的任意两种组合
