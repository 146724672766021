import { $t } from "@/plugins/i18n";
const { VITE_HIDE_HOME } = import.meta.env;
const Layout = () => import("@/layout/index.vue");

// export default {
//   path: "/",
//   name: "category",
//   component: Layout,
//   redirect: "/category/index",
//   meta: {
//     icon: "homeFilled",
//     // title: $t("menus.hshome"),
//     title: "纸样管理",
//     rank: 0
//     // showLink: false
//   },
//   children: [
//     {
//       path: "/category/categoryManager",
//       name: "categoryManager",
//       component: () => import("@/views/category/categoryManager.vue"),
//       meta: {
//         title: "添加/修改纸样",
//         keepAlive: false,
//         showLink: false
//       }
//     }
//   ]
// } as RouteConfigsTable;
export default {
  path: "/",
  name: "Home",
  component: Layout,
  redirect: "/welcome",
  meta: {
    icon: "homeFilled",
    title: $t("menus.hshome"),
    rank: 0,
    // showLink: false
  },
  children: [
    {
      path: "/welcome",
      name: "Welcome",
      component: () => import("@/views/welcome/index.vue"),
      meta: {
        title: $t("menus.hshome"),
        showLink: false
      }
    }
  ]
} as RouteConfigsTable;
