// 模拟后端动态生成路由
import { MockMethod } from "vite-plugin-mock";
// import category from '../src/assets/svg/category.svg'

/**
 * roles：页面级别权限，这里模拟二种 "admin"、"common"
 * admin：管理员角色
 * common：普通角色
 */

const permissionRouter = {
  path: "/permission",
  meta: {
    title: "menus.permission",
    icon: "lollipop",
    rank: 10,
    showLink: false
  },
  children: [
    {
      path: "/permission/page/index",
      name: "PermissionPage",
      meta: {
        title: "menus.permissionPage",
        roles: ["admin", "common"]
      }
    },
    {
      path: "/permission/button/index",
      name: "PermissionButton",
      meta: {
        title: "menus.permissionButton",
        roles: ["admin", "common"],
        auths: ["btn_add", "btn_edit", "btn_delete"]
      }
    }
  ]
};
const categoryRouter = {
  path: "/category",
  meta: {
    // icon: category,
    title: "纸样管理",
    rank: 2
  },
  children: [
    {
      path: "/category/index",
      name: "category",
      meta: {
        title: "纸样管理",
        keepAlive: false,
        showLink: true
      }
    },
    {
      path: "/category/categoryManager",
      name: "categoryManager",
      meta: {
        title: "纸样",
        showLink: false
      }
    }
  ]
};
const tabsRouter = {
  path: "/tabs",
  meta: {
    icon: "IF-pure-iconfont-tabs",
    title: "menus.hstabs"
    // rank: tabs
  },
  children: [
    {
      path: "/tabs/index",
      name: "Tabs",
      meta: {
        title: "menus.hstabs",
        roles: ["admin", "common"],
        showLink: false
      }
    },
    // query 传参模式
    {
      path: "/tabs/query-detail",
      name: "TabQueryDetail",
      meta: {
        // 不在menu菜单中显示
        showLink: false,
        roles: ["admin", "common"]
      }
    },
    // params 传参模式
    {
      path: "/tabs/params-detail/:id",
      component: "params-detail",
      name: "TabParamsDetail",
      meta: {
        // 不在menu菜单中显示
        showLink: false,
        roles: ["admin", "common"]
      }
    }
  ]
};
export default [
  {
    url: "/getAsyncRoutes",
    method: "get",
    response: () => {
      return {
        success: true,
        data: [permissionRouter, tabsRouter]
      };
    }
  }
] as MockMethod[];
