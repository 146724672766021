import companysvg from "@/assets/svg/company.svg";
export default {
  path: "/companyInfo",
  redirect: "/companyInfo/index",
  meta: {
    icon: companysvg,
    title: "企业信息",
    rank: 7
  },
  children: [
    {
      path: "/companyInfo/index",
      name: "companyInfo",
      component: () => import("@/views/companyInfo/index.vue"),
      meta: {
        title: "企业信息",
        keepAlive: false
      }
    }
  ]
} as RouteConfigsTable;
