import { useUserInfo } from "@/store/modules/useInfo";
import { isEmpty, isArray, isObject, isNull, uniq } from "lodash-es";
import { http } from "@/utils/http";
import { fixedFloat, sortSizes } from "@mhg/utils";
declare module "vue" {
  interface ComponentCustomProperties {
    $checkAuth: any;
    $noBind: any;
    $isEmpty: any;
    $formatNum: any;
    $mhgSetItem: any;
    $mhgGetItem: any;
    $formatSizeKey: any;
    $calTotalColorQuantity: any;
    $getStaticImage: any;
  }
}
function checkAuth(auths: []) {
  const user = useUserInfo();
  const userInfo = user.getUserInfo();
  if (!userInfo) return false;
  if (!userInfo.permissions) return false;
  return auths.some(auth => {
    return userInfo.permissions.indexOf(auth) != -1;
  });
}
function noBind(obj) {
  const res = obj ? JSON.parse(JSON.stringify(obj)) : "";
  return res;
}
async function setCustom(data) {
  type listResult = {
    code: number;
    data?: [];
    message: string;
    pageItem?: number;
  };
  await http.request<listResult>("post", "/main/style/getList", { data });
}
function formatNum(num1, num2, type) {
  return fixedFloat(num1 || 0, num2 || 0, type);
}
function mhgSetItem(name, obj) {
  const res = obj ? JSON.stringify(obj) : "";
  window.localStorage.setItem(name, res);
}
function mhgGetItem(name, type) {
  const obj = window.localStorage.getItem(name);
  let res = null;
  if (obj) {
    res = JSON.parse(obj);
  } else {
    if (isArray(type)) {
      res = [];
    } else if (isNull(type)) {
      res = null;
    } else if (isObject(type)) {
      res = new Object();
    } else {
      res = type;
    }
  }
  return res;
}
function formatSizeKey(data) {
  let allSizes = [];
  for (const color in data) {
    for (const size in data[color]) {
      allSizes.push(size);
    }
  }
  allSizes = uniq(allSizes);
  allSizes = sortSizes(allSizes);
  return allSizes;
}
function calTotalColorQuantity(data) {
  let total = 0;
  for (const size in data) {
    total = fixedFloat(total, data[size] || 0, "+");
  }
  return total;
}
// function getStaticImage(base, imageUrl, type) {
//   let path = ''
//   if (type == 'web') {
//     path =  new URL(`/src/assets/${base}/${imageUrl}`, import.meta.url).href;
//     // path = '/' + base + imageUrl
//     // return new URL(`/${base}/${imageUrl}`, import.meta.url).href;
//   } else if (type == '4G') {
//     path = 'https://service.58mhg.com' + imageUrl
//   }
//   return path
// }
function getStaticImage(base, imageUrl, type) {
  let path = ''
  if (type == 'web') {
    let url = ''
    url = base + imageUrl
    const modules: Record<string, any> = import.meta.glob("@/assets/**/*.{png,svg,jpg,jpeg}", { eager: true });
    if (modules[url]) {
      path = modules[url].default
    } else {
      path = ''
    }
  } else if (type == '4G') {
    path = 'https://service.58mhg.com' + imageUrl
  }
  return path
}
export default {
  install: app => {
    app.config.globalProperties["$checkAuth"] = checkAuth;
    app.config.globalProperties["$noBind"] = noBind;
    app.config.globalProperties["$isEmpty"] = isEmpty;
    app.config.globalProperties["$formatNum"] = formatNum;
    app.config.globalProperties["$mhgSetItem"] = mhgSetItem;
    app.config.globalProperties["$mhgGetItem"] = mhgGetItem;
    app.config.globalProperties["$formatSizeKey"] = formatSizeKey;
    app.config.globalProperties["$getStaticImage"] = getStaticImage;
    app.config.globalProperties["$calTotalColorQuantity"] =
      calTotalColorQuantity;
  }
};
