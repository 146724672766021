import cloudPrintersvg from "@/assets/svg/cloudPrinter.svg";

export default {
  path: "/cloudPrint",
  redirect: "/cloudPrint/index",
  meta: {
    icon: cloudPrintersvg,
    title: "云打印机设置",
    rank: 6
  },
  children: [
    {
      path: "/cloudPrint/index",
      name: "cloudPrint",
      component: () => import("@/views/cloudPrint/index.vue"),
      meta: {
        title: "云打印机设置",
        keepAlive: false,
        roles: ["others_printer"]
      }
    }
  ]
} as RouteConfigsTable;
