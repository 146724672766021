import vipsvg from "@/assets/svg/vip.svg";

export default {
  path: "/vip",
  redirect: "/vip/index",
  meta: {
    icon: vipsvg,
    title: "会员中心",
    rank: 5
  },
  children: [
    {
      path: "/vip/index",
      name: "vip",
      component: () => import("@/views/vip/index.vue"),
      meta: {
        title: "会员中心",
        keepAlive: false,
        roles: ["admin"]
      }
    }
  ]
} as RouteConfigsTable;
