import { http } from "@/utils/http";

/** 获取订单列表 */
export type Result = {
  code: number;
  data?: [] | { token: "" };
  message: string;
  pageItem?: number;
};
/**发送验证码 */
export const sendRegisterVerify = (data?: object) => {
  return http.request<Result>("post", "/main/auth/sendRegisterVerify", {
    data
  });
};
/**发送登录验证码 */
export const sendLoginVerify = (data?: object) => {
  return http.request<Result>("post", "/main/auth/sendLoginVerify", {
    data
  });
};
/**发送验证码 */
export const sendVerify = (data?: object) => {
  return http.request<Result>("post", "/main/auth/sendVerify", {
    data
  });
};

/**登录 */
export const userLogin = (url: string, data?: object) => {
  return http.request<Result>("post", url, { data });
};
/**注册 */
export const userRegister = (data: object) => {
  return http.request<Result>("post", "/main/auth/register", { data });
};
/**创建工厂 */
export const createFactory = (data: object) => {
  return http.request<Result>("post", "/main/factory/create", { data });
};

/**
 * 注销登录
 */
export const logOut = () => {
  return http.request<Result>("post", "/main/auth/logout");
};

/** 忘记密码 */
export const forgotPassword = (data: object) => {
  return http.request<Result>("post", "/main/auth/forgotPassword", { data });
};

/** 扫码登录检测 */
export const checkScanLogin = (data: object) => {
  return http.request<Result>("post", "/main/auth/checkScanLogin", { data });
};