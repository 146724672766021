import dayjs from "dayjs";
declare module "vue" {
  interface ComponentCustomProperties {
    $formatDate: any;
  }
}
const formatDate = (date: string, type?: number) => {
  switch (type) {
    case 1:
      return dayjs(date).format("YYYY-MM-DD");
    case 2:
      return dayjs(date).format("HH:mm:ss");
    default:
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  }
};
export default {
  install: app => {
    app.config.globalProperties["$formatDate"] = formatDate;
  }
};
