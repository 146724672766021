import customerSvg from "@/assets/svg/customer.svg";
export default {
  path: "/customer",
  redirect: "/customer/index",
  meta: {
    icon: customerSvg,
    title: "客户管理",
    rank: 5
  },
  children: [
    {
      path: "/customer/index",
      name: "customer",
      component: () => import("@/views/customer/index.vue"),
      meta: {
        title: "客户管理",
        keepAlive: false,
        roles: ["customer"]
      }
    }
  ]
} as RouteConfigsTable;
