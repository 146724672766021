import salarysvg from "../../assets/svg/salary.svg";
export default {
  path: "/salary",
  redirect: "/salary/index",
  meta: {
    icon: salarysvg,
    title: "薪酬管理",
    rank: 3
  },
  children: [
    {
      path: "/salary/index",
      name: "salary",
      component: () => import("@/views/salary/index.vue"),
      meta: {
        title: "薪酬管理",
        keepAlive: false,
        roles: ["salary"]
      }
    },
    {
      path: "/category/categoryManager",
      name: "categoryManager",
      component: () => import("@/views/category/categoryManager.vue"),
      meta: {
        title: "添加/修改纸样",
        keepAlive: false,
        showLink: false
      }
    }
  ]
} as RouteConfigsTable;
